import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'
import Dropzone from 'dropzone'
import { getMetaValue, findElement, removeElement } from '../helpers'

Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = ['input', 'template']

  connect () {
    this.dropZone = new Dropzone(this.element, {
      url: this.url,
      headers: this.headers,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFileSize,
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: this.addRemoveLinks,
      previewTemplate: this.previewTemplate,
      previewsContainer: this.previewsContainer,
      thumbnailWidth: this.thumnailWidth,
      thumbnailHeight: this.thumnailHeight,
      thumbnailMethod: 'crop',
      autoQueue: false,
      resizeWidth: this.resizeWidth,
      resizeHeight: this.resizeHeight,
      resizeQuality: this.resizeQuality
    })

    this.hideFileInput()
    this.bindEvents()
  }

  // Private
  hideFileInput () {
    this.inputTarget.hidden = true
  }

  bindEvents () {
    this.dropZone.on('addedfile', (file) => {
      setTimeout(() => { file.accepted && (new DirectUploadController(this, file)).start() }, 500)
    })

    this.dropZone.on('canceled', (file) => {
      file.controller && file.controller.xhr.abort()
    })

    this.dropZone.on('processing', (file) => {
      this.submitButton.disabled = true
    })

    this.dropZone.on('queuecomplete', (file) => {
      this.submitButton.disabled = false
    })
  }

  get headers () { return { 'X-CSRF-Token': getMetaValue('csrf-token') } }

  get url () { return this.inputTarget.getAttribute('data-direct-upload-url') }

  get maxFiles () { return this.data.get('maxFiles') || 1 }

  get maxFileSize () { return this.data.get('maxFileSize') || 256 }

  get acceptedFiles () { return this.data.get('acceptedFiles') }

  get addRemoveLinks () { return this.data.get('addRemoveLinks') || false }

  get form () { return this.element.closest('form') }

  get submitButton () { return findElement(this.form, 'input[type=submit], button[type=submit]') }

  get previewTemplate () { return this.templateTarget.innerHTML }

  get previewsContainer () { return findElement(this.form, '.dropzone-previews') }

  get thumnailWidth () { return this.templateTarget.content.querySelector('img[data-dz-thumbnail]')?.width }

  get thumnailHeight () { return this.templateTarget.content.querySelector('img[data-dz-thumbnail]')?.height }

  get resizeWidth () { return this.data.get('resizeWidth') }

  get resizeHeight () { return this.data.get('resizeHeight') }

  get resizeQuality () { return this.data.get('resizeQuality') }
}

class DirectUploadController {
  constructor (source, file) {
    this.directUpload = new DirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start () {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

  // Private
  createHiddenInput () {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = this.source.inputTarget.name
    this.file.previewTemplate.appendChild(input)
    return input
  }

  directUploadWillStoreFileWithXHR (xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent (xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener('progress', event => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress (event) {
    const progress = event.loaded / event.total * 100
    findElement(this.file.previewTemplate, 'progress').value = progress
  }

  emitDropzoneUploading () {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit('processing', this.file)
  }

  emitDropzoneError (error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit('error', this.file, error)
    this.source.dropZone.emit('complete', this.file)
  }

  emitDropzoneSuccess () {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit('success', this.file)
    this.source.dropZone.emit('complete', this.file)
  }
}
