import { Controller } from '@hotwired/stimulus'
import { load } from 'recaptcha-v3'
import 'form-request-submit-polyfill'

let recaptcha = null

export default class RecaptchaController extends Controller {
  async initialize () {
    if (this.disabled) return

    // Setup recaptcha if not already initialized
    if (!recaptcha) {
      recaptcha = await load(this.key, {
        autoHideBadge: true,
        explicitRenderParameters: { container: 'recaptcha-badge', badge: 'inline', size: 'invisible' }
      })
    }

    this.input = document.createElement('input')
    this.input.type = 'hidden'
    this.input.name = 'g-recaptcha-response'
    this.element.appendChild(this.input)
  }

  disconnect () {
    clearTimeout(this.timeout)
  }

  async submit (e) {
    // Proceed as normal if disabled or token already exists
    if (this.disabled || this.input.value) return

    // Stop form from submitting
    e.preventDefault()

    // Get recepatcha token
    this.input.value = await recaptcha.execute(this.action)

    // Tokens have an expiry of 2 minutes, so clear after 110 seconds
    this.timeout = setTimeout(() => { this.input.value = '' }, 110 * 1000)

    // Resubmit, allowing Turbo to do its thing
    this.element.requestSubmit()
  }

  get key () {
    return document.body.dataset.recaptchaKey
  }

  get action () {
    return this.data.get('action')
  }

  get disabled () {
    return !this.key
  }
}
