import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  mark () {
    this.element.classList.add('dirty')
  }

  unmark () {
    this.element.classList.remove('dirty')
  }
}
