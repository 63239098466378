// Polyfill for IE
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/reportValidity
if (!HTMLFormElement.prototype.reportValidity) {
  HTMLFormElement.prototype.reportValidity = function () {
    const submitButtons = this.querySelectorAll('button, input[type=submit]')
    for (let i = 0; i < submitButtons.length; i++) {
      // Filter out <button type="button">, as querySelectorAll can't
      // handle :not filtering
      if (submitButtons[i].type === 'submit') {
        submitButtons[i].click()
        return
      }
    }
  }
}
