import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

const release = `sailboat-guide@${import.meta.RAILS_ENV}-${import.meta.SOURCE_VERSION}`

if (import.meta.RAILS_ENV === 'production') {
  Sentry.init({
    dsn: 'https://99a55ddd033c4e7c9c596fcbe089f27a@o490508.ingest.sentry.io/3782437',

    // To set your release version
    release,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01
  })
}
