import { Controller } from '@hotwired/stimulus'
import debounce from '../lib/debounce'

export default class extends Controller {
  static targets = ['button']

  initialize () {
    this.submit = debounce(this.submit.bind(this), 400)
  }

  submit (event) {
    this.element.dataset.turboScroll = false

    const button = (this.hasButtonTarget && this.buttonTarget) ||
      this.element.querySelector('button, input[type=submit]')

    button && !button.disabled ? button.click() : this.element.submit()
  }
}
