import Controller from './filter_base'

export default class extends Controller {
  static targets = ['label', 'summary', 'input']

  get summary () {
    if (this.inputTarget.value) {
      return this.inputTarget.options[this.inputTarget.selectedIndex]?.innerHTML
    }
  }
}
