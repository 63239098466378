import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'button']

  connect () {
    this.buttonTarget.hidden = !document.queryCommandSupported('copy')
  }

  copy (event) {
    event.preventDefault()

    const value = this.sourceTarget.value || this.sourceTarget.innerText
    navigator.clipboard.writeText(value)

    // Update Title
    const tooltip = this.buttonTarget.__uikit__.tooltip
    const originalTitle = tooltip.title
    tooltip.title = 'Copied!'
    tooltip.hide()
    tooltip.show()
    setTimeout(() => { tooltip.title = originalTitle }, 3000)
  }
}
