import Controller from './filter_base'

export default class extends Controller {
  static targets = ['label', 'summary', 'min', 'max']
  static maxYear = new Date().getFullYear() + 1
  static minYear = 1900

  updateValidations () {
    this.minTarget.max = this.maxTarget.value || this.maxYear
    this.maxTarget.min = this.minTarget.value || this.minYear
  }

  get summary () {
    return this.formatRange(this.min, this.max)
  }

  get min () {
    return this.minTarget.value
  }

  get max () {
    return this.maxTarget.value
  }
}
