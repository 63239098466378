import Controller from './filter_base'

export default class extends Controller {
  static targets = ['label', 'summary', 'start', 'end']

  get summary () {
    if (!this.start && !this.end) return

    return [this.dateSummary(this.start), this.dateSummary(this.end)].join(' — ')
  }

  get start () {
    return this.utcDate(this.startTarget.value)
  }

  get end () {
    return this.utcDate(this.endTarget.value)
  }

  dateSummary (date) {
    if (!date) return

    return date.toLocaleDateString('en-us', { month: 'short', day: 'numeric' })
  }

  utcDate (string) {
    if (!string) return

    const date = new Date(string)
    return new Date(date.getTime() + (date.getTimezoneOffset() * 60000))
  }
}
