import { Controller } from '@hotwired/stimulus'
import '../polyfills/report-validity'

export default class extends Controller {
  initialize () {
    this.updateSummary()
  }

  update (event) {
    if (!this.validate(event)) return false
    this.updateValidations()
    this.updateSummary()
  }

  validate (event) {
    return event.target.reportValidity()
  }

  updateSummary () {
    const summary = this.summary

    this.labelTarget.hidden = !!summary
    this.summaryTarget.hidden = !summary
    this.summaryTarget.innerText = summary
  }

  updateValidations () { }

  formatRange (min, max, desc = null) {
    if (!min && !max) return

    let summary = ''

    if (min && max) {
      summary += `${min} – ${max}`
    } else if (!max) {
      summary += `>${min}`
    } else if (!min) {
      summary += `<${max}`
    }

    if (desc) summary += ` ${desc}`

    return summary
  }

  get summary () {
  }
}
