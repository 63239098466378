import { Controller } from '@hotwired/stimulus'

function cssVar (size) {
  return getComputedStyle(document.documentElement).getPropertyValue(`--uk-breakpoint-${size}`)
}

const breakpoints = {
  xs: `(max-width: ${cssVar('s')})`, // fixme: subtract 1
  s: `(min-width: ${cssVar('s')})`,
  m: `(min-width: ${cssVar('m')})`,
  l: `(min-width: ${cssVar('l')})`,
  xl: `(min-width: ${cssVar('lx')})`
}

export default class extends Controller {
  connect () {
    Object.entries(breakpoints).forEach(([size, query]) => {
      const mediaQuery = window.matchMedia(query)
      if (mediaQuery.matches) {
        Array.from(this.element.attributes).forEach(attr => {
          const [name, attrSize] = attr.nodeName.split('@')
          if (size === attrSize) {
            this.element.setAttribute(name, attr.nodeValue)
          }
        })
      }
    })
  }
}
