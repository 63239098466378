import { Controller } from '@hotwired/stimulus'

// Remember the scroll position when rendering a new page via Turbo
export default class Scroll extends Controller {
  static top = 0

  remember (event) {
    Scroll.top = document.scrollingElement.scrollTop
  }

  static restore () {
    if (this.top) {
      document.scrollingElement.scrollTop = Scroll.top
    }
    this.top = 0
  }
}

document.addEventListener('turbo:render', () => Scroll.restore())
