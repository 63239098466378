import { Controller } from '@hotwired/stimulus'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = ['slideshowModal', 'slideshow']

  show (event) {
    event.preventDefault()
    const { index } = event.target.closest('*[data-index]').dataset

    this.slideshowModal.show()
    this.slideshow.show(index)
  }

  keyup (event) {
    if (!this.slideshowModal.isToggled()) return

    switch (event.keyCode) {
      case 37:
        this.slideshow.show('previous')
        break
      case 39:
        this.slideshow.show('next')
        break
    }
  }

  get slideshowModal () {
    return UIkit.modal(this.slideshowModalTarget)
  }

  get slideshow () {
    return UIkit.slideshow(this.slideshowTarget)
  }
}
