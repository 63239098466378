import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // If a filter form exists on the page, update the q input and submit that instead
  perform (e) {
    const filter = document.querySelector('form[id*="filter-"]')

    if (filter) {
      e.preventDefault()

      filter.elements.q.value = this.element.elements.q.value
      filter.submit()
    }
  }
}
