import { Controller } from '@hotwired/stimulus'

// Stimulus controller to save location of same-origin iframe in hash of parent
// window and restore on reload.
//
//   <iframe src="…"
//           data-controller="iframe-location"
//           data-action="load->iframe-location#save">
//   </frame>
//
export default class extends Controller {
  connect () {
    this.base = this.element.src
    this.restore()
    window.addEventListener('hashchange', () => this.restore(), false)
  }

  // Save the iframe location to the current location hash
  save () {
    this.hashLocation = this.iframeLocation
  }

  // Restore the current location hash in the iframe
  restore () {
    this.iframeLocation = this.hashLocation
  }

  get hashLocation () {
    return window.location.hash.slice(1)
  }

  set hashLocation (newLocation) {
    if (this.hashLocation !== this.iframeLocation) {
      window.location.hash = this.iframeLocation
    }
  }

  // Get the current location of the iframe, excluding the original base url
  get iframeLocation () {
    return this.element.contentWindow.location.toString().replace(this.base, '')
  }

  // Update the iframe location
  set iframeLocation (newLocation) {
    if (newLocation && newLocation !== this.iframeLocation) {
      this.element.contentWindow.location = this.base + newLocation
    }
  }
}
