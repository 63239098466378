import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  up (e) {
    e.preventDefault()
    this.inputTarget.stepUp()
    this.change()
  }

  down (e) {
    e.preventDefault()
    this.inputTarget.stepDown()
    this.change()
  }

  change () {
    this.inputTarget.dispatchEvent(new Event('input', { bubbles: true }))
  }
}
