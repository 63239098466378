import Controller from './filter_base'
import numeral from 'numeral'

export default class extends Controller {
  static targets = ['label', 'summary', 'min', 'max', 'currency']

  updateValidations () {
    this.minTarget.max = this.maxTarget.value
    this.maxTarget.min = this.minTarget.value || 0
  }

  get summary () {
    return this.formatRange(this.min, this.max, this.currency)
  }

  get min () {
    return this.formatPrice(this.minTarget.value)
  }

  get max () {
    return this.formatPrice(this.maxTarget.value)
  }

  get currency () {
    return this.currencyTarget.value
  }

  get currencySymbol () {
    return '$'
  }

  formatPrice (value, format = '0,0') {
    if (!value) return
    return this.currencySymbol + numeral(value).format(format)
  }
}
