import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js/pure'

export default class extends Controller {
  static targets = ['button', 'card', 'error', 'paymentIntentId']

  style = {
    base: {
      color: '#666',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontSize: '16px',
      '::placeholder': {
        color: '#999'
      }
    },
    invalid: {
      color: '#f0506e',
      iconColor: '#f0506e'
    }
  }

  async connect () {
    if (this.disabled) return

    // Disable the button until we have Stripe set up on the page
    this.buttonTarget.disabled = true

    this.stripe = await loadStripe(this.data.get('key'))
    const elements = this.stripe.elements()
    this.card = elements.create('card', { style: this.style })
    this.card.mount(this.cardTarget)
    this.card.on('change', e => this.cardChange(e))
  }

  pay (event) {
    if (this.disabled) return

    event.preventDefault()
    event.stopPropagation()

    this.loading = true
    this.stripe.confirmCardPayment(this.data.get('secret'), {
      receipt_email: this.data.get('email'),
      payment_method: {
        card: this.card
      }
    })
      .then(result => {
        if (result.error) {
          this.error(result.error)
        } else {
          this.complete(result.paymentIntent)
        }
      })
  }

  cardChange (event) {
    // Disable the Pay button if there are no card details in the Element
    this.buttonTarget.disabled = !event.complete
    this.errorTarget.textContent = event.error ? event.error.message : ''
  }

  complete (paymentIntent) {
    this.loading = false
    this.paymentIntentIdTarget.value = paymentIntent.id
    this.disable()
    this.element.submit()
  }

  error (error) {
    this.loading = false
    this.errorTarget.textContent = error.message
    setTimeout(function () {
      this.errorTarget.textContent = ''
    }, 4000)
  }

  set loading (isLoading) {
    this.buttonTarget.disabled = isLoading
    this.element.classList.toggle('loading', isLoading)
  }

  get loading () {
    return this.element.classList.contains('loading')
  }

  disable () {
    return this.data.set('status', 'disabled')
  }

  get disabled () {
    return this.data.get('status') !== 'enabled'
  }
}
