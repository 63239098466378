import Controller from './filter_base'

export default class extends Controller {
  static targets = ['label', 'summary', 'adults', 'children']

  get summary () {
    if (!this.adults && !this.children) return

    return [
      this.pluralize(this.adults, { one: 'adult', many: 'adults' }),
      this.pluralize(this.children, { one: 'child', many: 'children' })
    ].filter(Boolean).join(', ')
  }

  get adults () {
    return Number(this.adultsTarget.value)
  }

  get children () {
    return Number(this.childrenTarget.value)
  }

  pluralize (number, { one, many }) {
    if (number <= 0) return

    const label = number === 1 ? one : many
    return `${number} ${label}`
  }
}
