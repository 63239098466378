import Controller from './filter_base'

export default class extends Controller {
  static targets = ['label', 'summary', 'min', 'max', 'units']

  updateValidations () {
    this.minTarget.max = this.maxTarget.value
    this.maxTarget.min = this.minTarget.value || 0
  }

  get summary () {
    return this.formatRange(this.min, this.max, this.units)
  }

  get min () {
    return this.minTarget.value
  }

  get max () {
    return this.maxTarget.value
  }

  get units () {
    return this.unitsTarget.value
  }
}
