import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['label', 'input']

  initialize () {
    this.update()
  }

  update () {
    this.labelTarget.innerText = this.inputTargets
      .filter(i => i.checked)
      .map(i => {
        const label = document.querySelector(`label[for="${i.id}"]`)?.innerText.trim()
        return label || i.value
      })
      .join('-')
  }
}
