import Controller from './filter_base'

export default class extends Controller {
  static targets = ['label', 'summary', 'input']

  get summary () {
    const input = this.inputTargets.find(input => input.checked)

    if (input) {
      const label = input.form.querySelector(`label[for="${input.id}"]`)
      return label?.innerText.trim()
    }
  }
}
