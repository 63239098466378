import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['control', 'input']

  initialize () {
    this.toggle()
  }

  clear (event) {
    event.preventDefault()
    event.stopPropagation()
    this.inputTargets.forEach(input => {
      input.value = null
      input.dispatchEvent(new Event('input', { bubbles: true }))
      input.dispatchEvent(new Event('change', { bubbles: true }))
    })
  }

  toggle (e) {
    this.controlTarget.hidden = !this.any
  }

  get any () {
    return this.inputTargets.some(input => input.value)
  }
}
