import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    if (!navigator.share) {
      this.element.hidden = true
    }
  }

  click (e) {
    e.preventDefault()
    navigator.share({ title: this.data.get('title'), url: this.data.get('url') })
  }
}
