import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  toggle (e) {
    e.preventDefault()
    const system = e.target.hash.slice(1)
    document.cookie = `measurement-system=${system};max-age=31536000`
    window.location.reload()
  }
}
