import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.element.addEventListener('click', e => {
      if (window.history.length > 1) {
        e.preventDefault()
        window.history.back()
      }
    })
  }
}
