import Litepicker from 'litepicker'
import 'litepicker/dist/plugins/mobilefriendly'

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['start', 'end']

  connect () {
    this.picker = new Litepicker({
      element: this.startTarget,
      elementEnd: this.endTarget,
      plugins: ['mobilefriendly'],
      inlineMode: true,
      singleMode: false,
      allowRepick: true,
      autoRefresh: true,
      numberOfMonths: 2,
      numberOfColumns: this.element.dataset.columns ? Number(this.element.dataset.columns) : 2,
      switchingMonths: 1,
      minDate: new Date().setDate(new Date().getDate() + 1),
      setup: (picker) => {
        ['selected', 'clear:selection'].forEach(event => {
          picker.on(event, () => {
            // Trigger change events on inputs
            [this.startTarget, this.endTarget].forEach(input => {
              input.dispatchEvent(new Event('input', { bubbles: true }))
              input.dispatchEvent(new Event('change', { bubbles: true }))
            })
          })
        })
      }
    })
  }
}
