import Controller from './filter_base'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = ['label', 'summary', 'distance', 'units', 'place', 'currentLocation', 'lat', 'lng']

  initialize () {
    super.initialize()

    if (navigator.geolocation) {
      UIkit.util.on(this.element, 'show', () => {
        if (!this.coordinates && !this.place) this.getCurrentPosition()
      })
    }
  }

  getCurrentPosition (e) {
    if (e) e.preventDefault()

    navigator.geolocation.getCurrentPosition(
      this.setCurrentPosition.bind(this),
      this.currentPositionFailed.bind(this)
    )

    this.element.classList.add('loading')
  }

  setCurrentPosition (position) {
    this.element.classList.remove('loading')

    this.latTarget.value = position.coords.latitude
    this.lngTarget.value = position.coords.longitude
    this.placeTarget.value = null;
    // Setting value does not dispatch event, so we'll force it
    [this.latTarget, this.lngTarget, this.placeTarget].forEach(input => {
      input.dispatchEvent(new Event('input', { bubbles: true }))
      input.dispatchEvent(new Event('change', { bubbles: true }))
    })
  }

  currentPositionFailed (error) {
    console.error(error)
    this.element.classList.remove('loading')
    this.currentLocationTarget.hidden = true
  }

  get summary () {
    this.placeTarget.placeholder = this.coordinates ? 'Here' : 'Anywhere'

    if (this.place) {
      return `<${this.distance} ${this.units} from ${this.place}`
    } else if (this.coordinates) {
      return `<${this.distance} ${this.units} from ${this.placeTarget.placeholder}`
    }
  }

  get distance () {
    return this.distanceTarget.value
  }

  get units () {
    return this.unitsTarget.value
  }

  get place () {
    return this.placeTarget.value
  }

  get coordinates () {
    return this.latTarget.value && this.lngTarget.value
  }
}
