// If this page is embedded in an iframe, let the parent know when to resize.
if (window.parent !== window) {
  function resize () {
    window.parent.postMessage({
      name: 'resize',
      href: window.location.href,
      height: document.body.offsetHeight + 'px'
    }, '*')
  }

  document.addEventListener('DOMContentLoaded', function () {
    resize()
    new ResizeObserver(resize).observe(document.body)
  })
}
